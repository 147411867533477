import "./../sass/master.scss";

import GridItem from "../components/griditem";

export default function Gallery() {
	const GALLERY_DATA = [
		{
			id: 1,
			thumbnail: require("./../assets/gallery/Aviewfromtarnhows.webp"),
			title: "A view from Tarn Hows",
			date: "2021",
			description:
				"[SOLD] Gouache and 24k gold leaf on pomegranate-stained paper",
			details: "21cm x 29.7cm",
		},
		{
			id: 3,
			thumbnail: require("./../assets/gallery/dayandnightcarpet.webp"),
			title: "Day & Night Carpet",
			date: "2021",
			description:
				"Gouache and 24k gold leaf on hot pressed water colour paper",
			details: "21cm x 29.7cm",
		},
		{
			id: 16,
			thumbnail: require("./../assets/gallery/welcometotheplayground.webp"),
			title: "Welcome to the Playground",
			date: "2021",
			description: "[SOLD] Coloured Pencil on paper",
			details: "21cm x29.7cm",
		},
		{
			id: 14,
			thumbnail: require("./../assets/gallery/squareburst.webp"),
			title: "Square Burst",
			date: "2021",
			description: "Shell gold and gouache on hemp paper",
			details: "20cm x 25cm",
		},

		{
			id: 4,
			thumbnail: require("./../assets/gallery/enclosedarch.webp"),
			title: "Enclosed Arch",
			date: "2021",
			description: "Gouache and shell gold on indian sunn hemp paper",
			details: "21cm x 25cm",
		},
		{
			id: 15,
			thumbnail: require("./../assets/gallery/tropical.webp"),
			title: "Rectangle Burst",
			date: "2021",
			description: "Shell gold and gouache on hemp paper",
			details: "20cm x 25cm",
		},

		{
			id: 6,
			thumbnail: require("./../assets/gallery/goldencircle.webp"),
			title: "Golden Shamsa",
			date: "2021",
			description:
				"Gouache and 24k gold leaf on hot pressed water colour paper",
			details: "21cm x 29.7cm",
		},
		{
			id: 7,
			thumbnail: require("./../assets/gallery/greenwaves.webp"),
			title: "10-fold geometry",
			date: "2021",
			description: "Watercolours on Hahnemuhle Buggra Bütten",
			details: "52 x 75cm",
		},
		{
			id: 8,
			thumbnail: require("./../assets/gallery/indianshamsa.webp"),
			title: "Shamsa",
			date: "2021",
			description:
				"Gouache and coloured pencil on pomegranate-stained paper",
			details: "20cm x 28cm",
		},
		{
			id: 9,
			thumbnail: require("./../assets/gallery/inthenight.webp"),
			title: "Sailing into the night",
			date: "2021",
			description:
				"[SOLD] 24k gold leaf and gouache on hemp paper mounted on nepalese lokta paper",
			details: "14.8cm x 21cm",
		},
		{
			id: 10,
			thumbnail: require("./../assets/gallery/khatam.webp"),
			title: "Khatam Constellation",
			date: "2021",
			description:
				"Gouache and 24k gold leaf mounted on indigo-stained cotton rag paper",
			details: "21cm x 29.7cm",
		},
		{
			id: 11,
			thumbnail: require("./../assets/gallery/ontheriver.webp"),
			title: "On the River",
			date: "2021",
			description:
				"[SOLD] 24k gold leaf and shell gold hemp paper mounted on nepalese lokta paper	",
			details: "14.8cm x 21cm",
		},
		{
			id: 5,
			thumbnail: require("./../assets/gallery/flowernaut.webp"),
			title: "Flower-Naut",
			date: "2021",
			description: "Gouache and 24k gold leaf on tea-stained paper",
			details: "18cm x 26cm",
		},
	];
	return (
		<div className="page" id="gallery">
			<div className="page__inside">
				<div className="grid">
					{GALLERY_DATA.map((i) => {
						return (
							<GridItem
								key={i.id}
								thumbnail={i.thumbnail}
								title={i.title}
								date={i.date}
								description={i.description}
								details={i.details}
							/>
						);
					})}
				</div>
			</div>
			<div className="scrolldown">
				<div className="scrolldown__text">SCROLL DOWN</div>
			</div>
		</div>
	);
}
