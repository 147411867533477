import "./App.css";

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Home from "./pages/home";
import Aboutme from "./pages/aboutme";
import Gallery from "./pages/gallery";
import Shop from "./pages/shop";
import Embroidery from "./pages/embroidery";
import Contactme from "./pages/contactme";
import Inprogress from "./pages/inprogress";

import Layout from "./layout";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="aboutme" element={<Aboutme />} />
					<Route path="inprogress" element={<Inprogress />} />
					<Route path="gallery" element={<Gallery />} />
					<Route path="shop" element={<Shop />} />
					<Route path="embroidery" element={<Embroidery />} />
					<Route path="contactme" element={<Contactme />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
