import "./../sass/master.scss";

import Inprogessitem from "../components/inprogressitem";

export default function Inprogress() {
	const INPROGRESS_DATA = [
		{
			id: 1,
			thumbnail: require("./../assets/workinprogress/IMG_0302.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
		{
			id: 2,
			thumbnail: require("./../assets/workinprogress/IMG_0304.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
		{
			id: 3,
			thumbnail: require("./../assets/workinprogress/IMG_0309.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
		{
			id: 4,
			thumbnail: require("./../assets/workinprogress/IMG_0310.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
		{
			id: 5,
			thumbnail: require("./../assets/workinprogress/IMG_0311.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
	];

	return (
		<div className="page" id="embroidery">
			<div className="page__inside">
				<div className="grid">
					{INPROGRESS_DATA.map((i) => {
						return <Inprogessitem key={i.id} thumbnail={i.thumbnail} />;
					})}
				</div>
			</div>
			<div className="scrolldown">
				<div className="scrolldown__text">SCROLL DOWN</div>
			</div>
		</div>
	);
}
