import "./../sass/master.scss";

import React, { useEffect } from "react";
import { gsap } from "gsap";

export default function Home() {
	const CAROUSEL_DATA = [
		{
			alt: "1",
			src: require("./../assets/carousel/IMG_0292.webp"),
		},
		{
			alt: "2",
			src: require("./../assets/carousel/IMG_0293.webp"),
		},
		{
			alt: "3",
			src: require("./../assets/carousel/IMG_0294.webp"),
		},
		{
			alt: "4",
			src: require("./../assets/carousel/IMG_0295.webp"),
		},
		{
			alt: "5",
			src: require("./../assets/carousel/IMG_0297.webp"),
		},
		{
			alt: "6",
			src: require("./../assets/carousel/IMG_0298.webp"),
		},
		{
			alt: "7",
			src: require("./../assets/carousel/IMG_0300.webp"),
		},
	];

	var carouselTL = gsap.timeline({
		onComplete: function () {
			this.revert();
			this.restart();
		},
	});
	var carouselImages = document.getElementsByClassName("carouselSlides");

	useEffect(() => {
		for (var i = 0; i < carouselImages.length; i++) {
			carouselTL.to(carouselImages[i], {
				autoAlpha: 1,
				delay: 3,
			});
		}
	}, []);

	return (
		<div id="home">
			<img
				className="carouselItem carouselPlaceholder"
				src={require("./../assets/carousel/IMG_0290.webp")}
				alt=""
			/>

			{CAROUSEL_DATA.map((i) => {
				return (
					<img
						className="carouselItem carouselSlides"
						key={i.alt}
						src={i.src}
						alt={i.alt}
					/>
				);
			})}
		</div>
	);
}
