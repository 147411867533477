import "./../sass/master.scss";

import React, { useState } from "react";
import Image from "react-image-enlarger";

export default function GridItem({
	thumbnail,
	title,
	date,
	description,
	details,
}) {
	const [zoomed, setZoomed] = useState(false);
	const currentYear = new Date().getFullYear();

	return (
		<div className="grid__item">
			<div className="grid__image__wrapper">
				<Image
					className="grid__image"
					zoomed={zoomed}
					src={thumbnail}
					alt=""
					onClick={() => setZoomed(true)}
					onRequestClose={() => setZoomed(false)}
					overlayColor="#171717"
				/>
			</div>
			<div className="grid__title">{title}&nbsp;</div>

			<div className="grid__copy">Haneefah Usmani &copy; {currentYear}</div>
			<div className="grid__description">{description}</div>
			<div className="grid__details">{details}</div>
		</div>
	);
}
