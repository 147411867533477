import "./sass/master.scss";

import React, { useEffect, useState } from "react";

import { gsap } from "gsap";

import { Outlet, useLocation, NavLink } from "react-router-dom";

import { FaInstagram } from "react-icons/fa";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Layout = () => {
	let location = useLocation();
	const currentYear = new Date().getFullYear();
	const [isHome, setisHome] = useState(true);

	useEffect(() => {
		gsap.to(".scrolldown", {
			autoAlpha: 0,
			scrollTrigger: {
				toggleActions: "play none none reverse",
				trigger: "#footer",
			},
		});
	}, []);

	useEffect(() => {
		if (!(location.pathname == "/")) {
			setisHome(false);
			gsap.set("#header", {
				top: 100,
				duration: 0,
			});
		} else {
			setisHome(true);
			gsap.set("#header", {
				top: "50%",
			});
		}
	}, [location.pathname]);

	return (
		<div id="wrapper">
			<div id="header" className={isHome ? "header--home" : ""}>
				<nav id="navigation">
					<ul>
						<li>
							<NavLink end to="/" className="navigation__item">
								HOME
							</NavLink>
						</li>
						<li>
							<NavLink to="/gallery" className="navigation__item">
								GALLERY
							</NavLink>
						</li>
						<li>
							<NavLink to="/embroidery" className="navigation__item">
								EMBROIDERY
							</NavLink>
						</li>
						<li>
							<NavLink to="/inprogress" className="navigation__item">
								IN PROGRESS
							</NavLink>
						</li>
						<li>
							<NavLink to="/aboutme" className="navigation__item">
								ABOUT ME
							</NavLink>
						</li>
						<li>
							<NavLink to="/contactme" className="navigation__item">
								CONTACT ME
							</NavLink>
						</li>
						<li>
							<a
								href="https://www.etsy.com/uk/shop/haneefahart/"
								className="navigation__item"
							>
								SHOP
							</a>
						</li>
						<li>
							<a
								href="https://www.instagram.com/haneefahusmani/?hl=en"
								target="_blank"
								className="navigation__item"
							>
								<FaInstagram size={30} />
							</a>
						</li>
					</ul>
				</nav>

				<div id="title">HANEEFAH USMANI</div>
			</div>

			<Outlet />

			<div id="footer">
				Haneefah Usmani &copy; {currentYear}. <br />
				<a href="http://www.saiba.digital" target="_blank">
					Created by Saiba Digital
				</a>
			</div>
		</div>
	);
};

export default Layout;
