import "./../sass/master.scss";

import React, { useState } from "react";

export default function Contactme() {
	var elements = document.getElementsByClassName("contact__input");

	const formSubmit = () => {
		for (var i = 0; i < elements.length; i++) {
			elements[i].value = "";
		}
		console.log("cleared");
	};
	return (
		<div className="page">
			<div className="insidepage">
				<form
					action="https://public.herotofu.com/v1/acfd9630-4e6f-11ed-8970-6943e4ac8982"
					method="post"
					id="contactme"
				>
					<div className="contactme__item">
						<label htmlFor="name">NAME</label>
						<input
							name="Name"
							id="name"
							className="contact__input"
							type="text"
							required
						/>
					</div>
					<div className="contactme__item">
						<label htmlFor="email">EMAIL</label>
						<input
							name="Email"
							id="email"
							className="contact__input"
							type="email"
							required
						/>
					</div>
					<div className="contactme__item">
						<label htmlFor="phone">PHONE</label>
						<input
							name="Phone"
							id="phone"
							className="contact__input"
							type="tel"
							required
						/>
					</div>
					<div className="contactme__item">
						<label htmlFor="subject">SUBJECT</label>
						<input
							name="Subject"
							id="subject"
							className="contact__input"
							type="text"
						/>
					</div>
					<div className="contactme__item">
						<label htmlFor="message">MESSAGE</label>
						<textarea
							name="Message"
							id="message"
							cols="30"
							rows="10"
							required
							className="contact__input"
						></textarea>
					</div>
					<div className="contactme__button">
						<input type="submit" value="SUBMIT" />
					</div>
				</form>
			</div>
		</div>
	);
}
