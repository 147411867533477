import "./../sass/master.scss";

export default function Aboutme() {
	return (
		<div className="page">
			<div className="page__inside">
				<div className="aboutme__wrapper">
					<div className="aboutme__image">
						<img
							src={require("./../assets/aboutme/IMG_7392.jpg")}
							alt=""
						/>
					</div>
					<div className="aboutme__text">
						Haneefah Usmani is an artist and graduate in graphic
						arts. Her journey in Islamic Art began with a summer
						course held at Art of Islamic pattern in August 2019.
						This opened the door for her to explore illumination,
						geometry and miniature painting. <br /> <br />
						She continues to learn, adapt and refine her skills by
						incorporating traditional mediums in her work. Through
						her work she aims to honour the magic and beauty of this
						world. Her work currently focusses on miniature painting
						and creating scenes that depict a world between earth
						and dreams. Her patterns are influenced by the interiors
						and exteriors of Islamic architecture as they hold many
						styles from free-flowing forms to still geometric
						shapes.
					</div>
				</div>
			</div>
		</div>
	);
}
