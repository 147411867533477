import "./../sass/master.scss";
import EmbroideryItem from "../components/embroideryitem";

export default function Embroidery() {
	const EMBROIDERY_DATA = [
		{
			id: 2,
			thumbnail: require("./../assets/embroidery/IMG_0278.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
		{
			id: 4,
			thumbnail: require("./../assets/embroidery/IMG_0281.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
		{
			id: 5,
			thumbnail: require("./../assets/embroidery/IMG_0282.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
		{
			id: 7,
			thumbnail: require("./../assets/embroidery/IMG_0286.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},

		{
			id: 1,
			thumbnail: require("./../assets/embroidery/IMG_0277.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},

		{
			id: 3,
			thumbnail: require("./../assets/embroidery/IMG_0279.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},

		{
			id: 6,
			thumbnail: require("./../assets/embroidery/IMG_0285.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},

		{
			id: 8,
			thumbnail: require("./../assets/embroidery/IMG_0287.webp"),
			title: "",
			date: "",
			description: "",
			details: "",
		},
	];
	return (
		<div className="page" id="embroidery">
			<div className="page__inside">
				<div className="embroidery__description">
					Combining hand-sewed embroidery and biomorphic patterns
				</div>
				<div className="grid">
					{EMBROIDERY_DATA.map((i) => {
						return <EmbroideryItem key={i.id} thumbnail={i.thumbnail} />;
					})}
				</div>
			</div>
			<div className="scrolldown">
				<div className="scrolldown__text">SCROLL DOWN</div>
			</div>
		</div>
	);
}
