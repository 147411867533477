import "./../sass/master.scss";

import React, { useState } from "react";
import Image from "react-image-enlarger";

export default function EmbroideryItem({ thumbnail, title }) {
	const [zoomed, setZoomed] = useState(false);
	const currentYear = new Date().getFullYear();

	return (
		<div className="grid__item embroidery__item">
			<div className="grid__image__wrapper">
				<Image
					className="grid__image"
					zoomed={zoomed}
					src={thumbnail}
					alt=""
					onClick={() => setZoomed(true)}
					onRequestClose={() => setZoomed(false)}
					overlayColor="#171717"
				/>
			</div>
		</div>
	);
}
